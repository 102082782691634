import React from "react";
import Layout from "../components/layout";
import { StaticImage } from "gatsby-plugin-image";

const IndexPage = () => {
  return (
    <Layout pageTitle="Home">
      <div className="relative">
        <StaticImage
          className="w-screen h-screen"
          src="../images/title.jpg"
          alt="contact"
        />
      </div>
      <div className="flex flex-col space-y-16 m-16 mx-10">
        <div className="max-w-4xl mx-auto">
          <p className="text-2xl">
            "Jede Reise beginnt mit dem ersten Schritt"{" "}
            <span className="text-lg whitespace-nowrap">- Konfuzius</span>
          </p>
        </div>
      </div>
    </Layout>
  );
};

export default IndexPage;
